import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth'; // Firebase v9+
import { getDatabase } from 'firebase/database'; // Firebase v9+
import { getFirestore} from "firebase/firestore";

import {DatabaseProvider, AuthProvider, FirestoreProvider, useFirebaseApp } from 'reactfire';

export default function FirebaseComponents({ children }) {
    const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

    // initialize Database and Auth with the normal Firebase SDK functions
    const database = getDatabase(app);
    const auth = getAuth(app);
    setPersistence(auth, browserLocalPersistence)
    const firestore = getFirestore(app)


    // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
    return (
        <AuthProvider sdk={auth}>
            <DatabaseProvider sdk={database}>
                <FirestoreProvider sdk={firestore}>
                    {children}
                </ FirestoreProvider>
            </DatabaseProvider>
        </AuthProvider>
    );
}
