import { useSigninCheck } from "reactfire";
import { navigate, useLocation } from "@reach/router";
import Continue from "./continue";
import { Loading } from "@nextui-org/react";

export const Guarded = (props) => {
  const { status, data: signInCheckResult } = useSigninCheck();
  const location = useLocation();
  if (status === "loading") {
    return <Loading color="#ff375f" />;
  }
  if (signInCheckResult.signedIn === true) {
    const { searchParams } = new URL(location.href);
    console.log(`%c[Connect Web Dashboard - Guard]%c`, "color:#ff4f44;font-weight:bold;", "", "Signed in!");
    if (searchParams.has("rdr")) {
      console.log(`%c[Connect Web Dashboard - Guard]%c`, "color:#ff4f44;font-weight:bold;", "", "Redirect found, redirecting...");
      return navigate(searchParams.get("rdr"), { replace: true });
    }
    return props.children;
  }
  console.log(`%c[Connect Web Dashboard - Guard]%c`, "color:#ff4f44;font-weight:bold;", "", "User is logged out, returning sign in component!");
  return <Continue location={location} />;
};
