import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseAppProvider } from 'reactfire';
import FirebaseComponents from "./components/GlobalFirebaseProvider";
import { CookiesProvider } from "react-cookie";

const firebaseConfig = {
    apiKey: "AIzaSyCYhHEBH-bkXBADELuwQX4NsoqaH7460pA",
    authDomain: "cider-collective.firebaseapp.com",
    databaseURL: "https://connect-cidercollective.firebaseio.com",
    projectId: "cider-collective",
    storageBucket: "cider-collective.appspot.com",
    messagingSenderId: "474254121753",
    appId: "1:474254121753:web:a6d9e3568656d192820388",
    measurementId: "G-Q3FL03JLBV"
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <FirebaseComponents>
            <App />
        </FirebaseComponents>
    </FirebaseAppProvider>
    </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
