import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "@reach/router";
import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { useSignInWithGoogle } from "../hooks/useSignInWithGoogle";
import useSignInWithOAuth from "../hooks/useSignInWithOAuth";
import useSignInWithToken from "../hooks/useSignInWithToken";
import { Loading } from "@nextui-org/react";
import { useSigninCheck } from "reactfire";

const appleOauthProvider = new OAuthProvider("apple.com");
appleOauthProvider.addScope("email");
appleOauthProvider.addScope("name");

const microsoftADProvider = new OAuthProvider("microsoft.com");
microsoftADProvider.addScope("openid");
microsoftADProvider.setCustomParameters({
  tenant: "358016f2-b726-4594-ae51-783a77899b42",
});
// https://connect-3rdpartyaccountcallback.ciderapp.workers.dev/discord?d=localhost:3000&t=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY2OTg1NjMyMiwiZXhwIjoxNjY5ODU5OTIyLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay1vczMzZUBjaWRlci1jb2xsZWN0aXZlLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwic3ViIjoiZmlyZWJhc2UtYWRtaW5zZGstb3MzM2VAY2lkZXItY29sbGVjdGl2ZS5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInVpZCI6IldGOGs2UFdMT29SdDVzWElNR3hoWElhN0RvQzIifQ.RLMSGQJuH1ejymT3q62rhyS4IM6Cbe4CBb9N4BzPAaFjUCc_7czGnYFAJB7IvegENghoaH5bOtxqjj3frRkNsXdxHPtkq6ZlNYBfOOGqEPLlghJQ0VQoFqfcoT-YLwKwqDai9_ZCZwjUXfdngWyTYVF_emzg7IwsoedLQyY6S8HmLg2qHntqI87OQLP5FS8Br-fatJUuCdL0CwdoOZltEaONJS7qwtREd-H8djisyqE06jOCUeaNNgz1GOY33eDn1Uy9ShN6HDZWx0H8lhXy_RmbO24-n0MLaCFJOntr-gJARIDZqoDyoKUgcdhCnQLFMxUsQU9TZSiu2kMRpb5qdw

const Continue = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { searchParams } = new URL(location.href);
  const { status, data: signInCheckResult } = useSigninCheck();
  const [signInWithGoogle, signInWitGoogleState] = useSignInWithGoogle();
  const [signInWithOAuth, signInWithOAuthState] = useSignInWithOAuth();
  const [signInWithToken, signInWithTokenState] = useSignInWithToken();
  const [tokenLogging, setTokenLogging] = useState(false)
  const embed = props.embed || false;

  const Login = (props) => {
    if (embed) {
      return (
        <div>
          <div className="content">{props.children}</div>
        </div>
      );
    }

    return (
      <div>
        <img
          src="https://raw.githubusercontent.com/ciderapp/cider.sh/main/assets/img/Cider-Logo.png"
          alt="logo"
          className="logo"
        />
        <h2>Cider Connect</h2>
        <h4 className="description">
          Let's get started. Log on to your account
        </h4>
        <div className="content">{props.children}</div>
      </div>
    );
  };

  const onSignin = useCallback(async () => {
    return navigate(`/dashboard`);
  }, [navigate]);

  useEffect(() => {
    if (
      signInWitGoogleState.success ||
      signInWithOAuthState.success ||
      signInWithTokenState.success
    ) {
      onSignin();
    }
    if (
      signInWitGoogleState.error ||
      signInWithOAuthState.error ||
      signInWithTokenState.error
    ) {
      return (
        <div>
          <Login>Error with login</Login>
        </div>
      );
    }
  }, [signInWithGoogle, signInWithOAuthState, signInWithTokenState, onSignin, signInWitGoogleState]);

  useEffect(() => {
    if (searchParams.has("ia")) {
      localStorage.setItem('ia', decodeURI(searchParams.get("ia")))
      localStorage.setItem('relay', "true")
    }
    if (searchParams.has("relay")) {
      localStorage.setItem('relay', "true")
    }

    if (!tokenLogging) {
      if (searchParams.has("fbtoken")) {
        signInWithToken(decodeURI(atob(searchParams.get("fbtoken"))));
        setTokenLogging(true)
      }
    }
  }, [tokenLogging, searchParams, signInWithToken])

  if (searchParams.has("fbtoken")) {
    return (
      <div>
        <Login>
          <Loading type="gradient" color="white">
            {" "}
            Logging in with Cider...{" "}
          </Loading>
        </Login>
      </div>
    );
  }
  if (status === "loading") {
    return (
      <div>
        <Login>
          <Loading type="gradient" color="white"></Loading>
        </Login>
      </div>
    );
  }

  if (signInCheckResult.signedIn === true) {
    if (params.rdr) navigate(params.rdr);
    onSignin();
  }

  return (
    <div>
      <Login>
        <button
          className="loginBtn"
          onClick={() => {
            signInWithGoogle(new GoogleAuthProvider());
          }}
        >
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
          >
            <path d="M11.99 13.9v-3.72h9.36c.14.63.25 1.22.25 2.05 0 5.71-3.83 9.77-9.6 9.77-5.52 0-10-4.48-10-10S6.48 2 12 2c2.7 0 4.96.99 6.69 2.61l-2.84 2.76c-.72-.68-1.98-1.48-3.85-1.48-3.31 0-6.01 2.75-6.01 6.12s2.7 6.12 6.01 6.12c3.83 0 5.24-2.65 5.5-4.22h-5.51v-.01Z" />
          </svg>
          Sign in with Google
        </button>
        <button
          className="loginBtn"
          onClick={() => {
            signInWithOAuth(appleOauthProvider);
          }}
        >
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
          >
            <path
              fillRule="evenodd"
              d="M15.3 3.832c.84-1.014 1.404-2.427 1.25-3.832-1.208.049-2.67.805-3.535 1.819-.777.898-1.457 2.335-1.273 3.712 1.346.105 2.722-.684 3.56-1.699m3.02 8.918c.034 3.632 3.186 4.841 3.22 4.857-.025.085-.502 1.722-1.66 3.413-1 1.462-2.038 2.919-3.674 2.949-1.607.029-2.123-.953-3.961-.953-1.836 0-2.41.923-3.932.982-1.578.06-2.78-1.581-3.79-3.037-2.06-2.98-3.635-8.42-1.52-12.092C4.054 7.045 5.932 5.89 7.97 5.861c1.55-.03 3.013 1.043 3.96 1.043.948 0 2.726-1.29 4.595-1.101.783.033 2.979.316 4.39 2.381-.114.07-2.621 1.53-2.594 4.566"
            />
          </svg>
          Sign in with Apple
        </button>
        <button
          className="loginBtn"
          onClick={() => {
            signInWithOAuth(microsoftADProvider);
          }}
        >
          <svg
            viewBox="0 -28.5 256 256"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
          >
            <path d="M118.432 187.698c32.89-5.81 60.055-10.618 60.367-10.684l.568-.12-31.052-36.935c-17.078-20.314-31.051-37.014-31.051-37.11 0-.182 32.063-88.477 32.243-88.792.06-.105 21.88 37.567 52.893 91.32 29.035 50.323 52.973 91.815 53.195 92.203l.405.707-98.684-.012-98.684-.013 59.8-10.564ZM0 176.435c0-.052 14.631-25.451 32.514-56.442l32.514-56.347 37.891-31.799C123.76 14.358 140.867.027 140.935.001c.069-.026-.205.664-.609 1.534s-18.919 40.582-41.145 88.25l-40.41 86.67-29.386.037c-16.162.02-29.385-.005-29.385-.057Z" />
          </svg>
          Sign in with Azure AD
        </button>
        <h5>OR</h5>
        <button className="loginBtn" disabled>
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
          >
            <path d="M23.99 6.13V6c0-.18-.01-.35-.06-.53A2.001 2.001 0 0 0 22 4H2a2.001 2.001 0 0 0-2 2v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2l-.01-11.87zm-2-.13L12 12 2 6h19.99zM2 18V8.33l8.97 5.38c.32.19.67.29 1.03.29s.71-.1 1.03-.29l8.96-5.38L22 18H2z" />
          </svg>
          Sign in with Email
        </button>
      </Login>
    </div>
  );
};

export default Continue;
