import { useUser, useDatabase, useDatabaseObjectData } from "reactfire";
import { Card } from "@nextui-org/react";
import { ref } from "firebase/database";

export default function ListeningToStatus() {
    const { status: authStatus, data: user } = useUser();
    const database = useDatabase();
    let { data: usr } = useDatabaseObjectData(ref(database, `users/${user.uid}`));

    if (usr?.nowPlaying && usr?.state?.playing === 1 && authStatus !== "loading") return (
        <>
            <Card
                isHoverable
                style={{
                    borderRadius: 10,
                    backgroundColor: "rgb(39 42 50)",
                    height: '100%',
                    display: "inline-flex",
                    flexWrap: "wrap",
                    padding: 20,
                    flexDirection: 'row',
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <img
                    style={{ width: 35, position: 'absolute', top: '10px', right: '10px', zIndex: 69420 }}
                    src="https://raw.githubusercontent.com/ciderapp/cider.sh/main/assets/img/Cider-Logo.png"
                    alt="icon"
                />
                <div className="listeningContainer">
                    <img
                        style={{ width: 65, height: 65, borderRadius: "10px" }}
                        src={usr?.nowPlaying?.attributes?.artwork.url.replace('{w}x{h}bb.jpg', '200x200bb.jpg')}
                        alt="icon"
                    />
                    <div className="listenContents">
                        <span><b>{usr?.nowPlaying?.attributes?.name}</b></span>
                        <span style={{ flexWrap: "nowrap" }}>By {usr?.nowPlaying?.attributes?.artistName} on {usr?.nowPlaying?.attributes?.albumName}</span>
                    </div>
                </div>
            </Card>
        </>
    );
}