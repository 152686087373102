import { useDatabase, useDatabaseObjectData } from "reactfire";
import { ref } from "firebase/database";
import { useEffect, useState } from "react";
// https://discord.com/api/oauth2/authorize?client_id=960067993226854431&redirect_uri=http://localhost:3000/api/callback/discord&response_type=code&scope=identify
import {Card, Col, Loading, Text, Grid} from "@nextui-org/react";
import ContinueIA from "../ContinueIA";

export default function RelayLtgh(props) {
  const database = useDatabase();
  const dbRef = ref(database, `parties/${props.id}`);

  const [room, setRoom] = useState(null); // [room, setRoom]
  const [track, setTrack] = useState("");
  const [isEnabled, setIsEnabled] = useState(true);

  let { dbstatus, data: party } = useDatabaseObjectData(dbRef);

  console.log(party)

  useEffect( () => {
    setIsEnabled(party?.active);
    setTrack(party?.data?.nowPlaying);
    setInterval(() => {
      setIsEnabled(party?.active);
      setTrack(party?.data?.nowPlaying);
    }, 1000)
  }, [party, isEnabled]);

  if ( dbstatus === "loading" ) {
    return <Loading color="white" type="gradient" size="sm" />;
  }

  if (!isEnabled) {
    return (
      <div>
        <h2>Listen Together</h2>
        <div className="description">
          Party is not active ):
        </div>
      </div>
    );
  }
  return (
    <div>
      <h2>Listen Together</h2>
      <div className="description" style={{ justifyItems: "normal" }}>
        <Grid.Container gap={2} justify="center">
          <Grid>
            { track && (<Card isHoverable isPressable variant="bordered">
              <Card.Header css={{position: "absolute", zIndex: 1, top: 5}}>
                <Col>
                  <Text size={36} weight="bold" transform="uppercase" color="rgb(255, 55, 95);">
                    <span>Listening to</span>

                  </Text>
                  <Text h4 color="rgb(255, 55, 95);">
                    {track.name}
                  </Text>
                </Col>
              </Card.Header>
              <Card.Image
                  src={track.artwork.url.replace("{w}x{h}", "500x500")}
                  objectFit="contain"
                  alt="Card image background"
              />
            </Card>)}
          </Grid>
        </Grid.Container>
        <br />
          <div id="ln" style={{ display: "flex", justifyContent: "center" }}>
            <ContinueIA rtd={{ command: "ltgh", id: props.id}}> Listen Along! </ContinueIA>
          </div>
        <br />
        <br />
      </div>
    </div>
  );
}
