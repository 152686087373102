import { useAuth, useDatabase, useSigninCheck } from 'reactfire';
import { Button, Loading } from '@nextui-org/react';
import { ref, set } from 'firebase/database';
import { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { hasCookie } from 'cookies-next';

export default function WidgetButton(props) {
	const { status, data: signInCheckResult } = useSigninCheck();
	const database = useDatabase();
	const auth = useAuth();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (isLoading && signInCheckResult?.user?.uid) {
			const hasRelayCookie = hasCookie('relay');
			const hasIaCookie = hasCookie('ia');
			const hasRelayStorage = localStorage.getItem('relay');

			if (hasRelayCookie || hasIaCookie || hasRelayStorage) {
				set(ref(database, `users/${signInCheckResult.user.uid}/relay`), {
					command: 'open',
					url: `https://connect.cidercollective.dev/relay/widget/${signInCheckResult.user.uid}`,
				});
			} else {
				window.open(
					`https://connect.cidercollective.dev/relay/widget/${signInCheckResult.user.uid}`,
					'_blank',
				);
			}
		}
	}, [auth, database, navigate, isLoading, signInCheckResult?.user?.uid]);

	const handleButtonClick = () => {
		setIsLoading(true);
	};

	if (status === 'loading') {
		return null;
	}

	if (signInCheckResult.signedIn === true) {
		return (
			<Button
				auto
				style={{ width: '100%' }}
				bordered
				color="error"
				icon={
					<svg width="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M468.53 236.03H486v39.94h-17.47v-39.94zm-34.426 51.634h17.47v-63.328h-17.47v63.328zm-33.848 32.756h17.47V191.58h-17.47v128.84zm-32.177 25.276h17.47V167.483h-17.47v178.17zm-34.448-43.521h17.47v-92.35h-17.47v92.35zm-34.994 69.879h17.47v-236.06h-17.525v236.06zM264.2 405.9h17.47V106.1H264.2v299.8zm-33.848-46.284h17.47V152.383h-17.47v207.234zm-35.016-58.85h17.47v-87.35h-17.47v87.35zm-33.847-20.823h17.47V231.98h-17.47v48.042zm-33.848 25.66h17.47v-99.24h-17.47v99.272zm-33.302 48.04h17.47V152.678H94.34v201zm-33.847-30.702h17.47V187.333h-17.47v135.642zM26 287.664h17.47v-63.328H26v63.328z"
							fill="currentColor"
						/>
					</svg>
				}
				onPress={handleButtonClick}
			>
				{isLoading ? <Loading color="white" type="gradient" size="sm" /> : props.children}
			</Button>
		);
	} else {
		return null;
	}
}
