import { useFirestore, useUser, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";
import { Card, Loading } from "@nextui-org/react";
import { useCallback } from "react";

export default function LastFMStatus() {
    const { status, data: user } = useUser();
    const firestore = useFirestore();
    const ref = doc(firestore, "users", user.uid, "accounts", "lastfm");
    const { fsstatus, data } = useFirestoreDocData(ref);

    const lastfmRedirect = useCallback(async () => {
        window.open(`https://linkaccount.cider.sh/lastfm`, '_blank', 'popup')
    }, []);

    if (fsstatus || status === "loading") return <Loading />;

    return (
        <>
            {data?.username ?
                <Card
                    isHoverable
                    style={{
                        borderRadius: 10,
                        backgroundColor: "#a40000",
                        width: '100%',
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "flex-start",
                        padding: 20,
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}
                >
                    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{ width: 35 }}>
                        <path d="M14.23 22.512l-1.1-2.99c-1.137 1.176-2.708 1.926-4.454 1.992l-0.012 0c-2.371 0-4.055-2.061-4.055-5.36 0-4.227 2.13-5.739 4.226-5.739 3.025 0 3.986 1.959 4.811 4.468l1.1 3.436c1.1 3.332 3.161 6.012 9.106 6.012 4.261 0 7.148-1.305 7.148-4.741 0-2.784-1.581-4.226-4.538-4.914l-2.197-0.481c-1.512-0.344-1.959-0.963-1.959-1.994 0-1.168 0.927-1.855 2.44-1.855 1.65 0 2.543 0.619 2.68 2.096l3.436-0.412c-0.275-3.092-2.405-4.365-5.911-4.365-3.093 0-6.116 1.169-6.116 4.915 0 2.338 1.134 3.814 3.986 4.501l2.337 0.55c1.753 0.413 2.336 1.134 2.336 2.13 0 1.271-1.238 1.788-3.575 1.788-0.12 0.009-0.26 0.015-0.401 0.015-2.619 0-4.806-1.847-5.33-4.309l-0.006-0.036-1.134-3.438c-1.444-4.466-3.746-6.116-8.316-6.116-5.053 0-7.732 3.196-7.732 8.625 0 5.225 2.68 8.043 7.491 8.043 0.145 0.009 0.314 0.014 0.485 0.014 1.994 0 3.826-0.692 5.27-1.848l-0.017 0.013z"></path>
                    </svg>
                    <div style={{ display: "inline-block", paddingLeft: 20 }}>
                        <h3 style={{ margin: "unset", textAlign: "left" }}>{data?.username}</h3>
                        <h5 style={{ float: "left" }}>Last.fm - Linked</h5>
                    </div>
                </Card>
                : <Card isHoverable
                    isPressable
                    style={{
                        borderRadius: 10,
                        backgroundColor: "#a40000",
                        width: '100%',
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "flex-start",
                        padding: 20,
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}
                    onPress={lastfmRedirect}
                >
                    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{ width: 35 }}>
                        <path d="M14.23 22.512l-1.1-2.99c-1.137 1.176-2.708 1.926-4.454 1.992l-0.012 0c-2.371 0-4.055-2.061-4.055-5.36 0-4.227 2.13-5.739 4.226-5.739 3.025 0 3.986 1.959 4.811 4.468l1.1 3.436c1.1 3.332 3.161 6.012 9.106 6.012 4.261 0 7.148-1.305 7.148-4.741 0-2.784-1.581-4.226-4.538-4.914l-2.197-0.481c-1.512-0.344-1.959-0.963-1.959-1.994 0-1.168 0.927-1.855 2.44-1.855 1.65 0 2.543 0.619 2.68 2.096l3.436-0.412c-0.275-3.092-2.405-4.365-5.911-4.365-3.093 0-6.116 1.169-6.116 4.915 0 2.338 1.134 3.814 3.986 4.501l2.337 0.55c1.753 0.413 2.336 1.134 2.336 2.13 0 1.271-1.238 1.788-3.575 1.788-0.12 0.009-0.26 0.015-0.401 0.015-2.619 0-4.806-1.847-5.33-4.309l-0.006-0.036-1.134-3.438c-1.444-4.466-3.746-6.116-8.316-6.116-5.053 0-7.732 3.196-7.732 8.625 0 5.225 2.68 8.043 7.491 8.043 0.145 0.009 0.314 0.014 0.485 0.014 1.994 0 3.826-0.692 5.27-1.848l-0.017 0.013z"></path>
                    </svg>
                    <div style={{ display: "inline-block", paddingLeft: 20 }}>
                        <h3 style={{ margin: "unset", textAlign: "left" }}>Link Last.fm</h3>
                    </div>
                </Card>
            }
        </>
    );
}
