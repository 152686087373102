import { useAuth } from "reactfire";

import {
    signInWithPopup,
    browserPopupRedirectResolver,
    OAuthProvider
} from "firebase/auth";

import { useRequestState } from "./useRequestState";
import { useCallback } from "react";

export default function useSignInWithOAuth() {
    const auth = useAuth();
    const { state, setLoading, setData, setError } = useRequestState();
    const signInWithProvider = useCallback(async (provider) => {
        setLoading(true);
        try {
            const result = await signInWithPopup(
                auth,
                provider,
                browserPopupRedirectResolver
            );
            const credential = OAuthProvider.credentialFromResult(result);
            setData(credential);
        } catch (error) {
            setError(error);
        }
    }, [auth, setData, setError, setLoading]);
    return [signInWithProvider, state];
}
