//window.open("cider://", '_blank', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
// width=500,height=150`)
import { useDatabase, useSigninCheck } from "reactfire";
import { Button, Loading } from "@nextui-org/react";
import { ref, set } from "firebase/database";
import { useState } from "react";
import { useNavigate } from "@reach/router";

export default function ContinueIA(props) {
  const { status, data: signInCheckResult } = useSigninCheck();
  const database = useDatabase();
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const [appOpened, setAppOpened] = useState(false);

  if (status === "loading") {
    return (
      <Button disabled size="lg" bordered color="error">
        <Loading color="white" type="gradient" size="sm" />
      </Button>
    );
  }
  if (signInCheckResult.signedIn === true) {
    return (
      <Button
        shadow
        color="error"
        size="lg"
        disabled={appOpened}
        onPress={() => {
          setState(true);

            window.location.href = "cider://"
            setTimeout(() => {
                if (document.hasFocus()) {
                    window.location.href = "https://cider.sh";
                } else {
                    console.log("Cider is installed");
                    set(
                        ref(database, `users/${signInCheckResult.user.uid}/relay`),
                        {
                            uuid: "any",
                            ...props.rtd
                        }
                    ).then(() => {
                        setState(false);
                        setAppOpened(true);
                    });

                }
            }, 1000);


        }}
      >
        {state ? (
          <Loading
            color="white"
            type="gradient"
            size="sm"
          />
        ) : appOpened ? (
                <>You can now close this tab</>
                ) : (
                    props.children
                )
        }
      </Button>
    );
  } else {
    return (
      <Button
        id={"la"}
        href={"#la"}
        shadow
        isHoverable
        autoFocus
        color="error"
        size="lg"
        onPress={() => {
          navigate(`/dashboard?rdr=${window.location.pathname}#la`);
        }}
      >
        Login to Continue
      </Button>
    );
  }
}
