
import { useUser } from "reactfire";
import { Modal, Tooltip, Button, useModal, Text } from "@nextui-org/react";
import Logout from "../teenies/logout";
import { useCallback, useEffect, useState } from "react";
import WidgetButton from "../teenies/widget";

export default function MenuControls() {
    const { status: authStatus } = useUser();
    const { setVisible, bindings } = useModal();

    const [ia, setIa] = useState({});
    const [ua, setUa] = useState({});
    const [appName, setAppName] = useState(null);
    useEffect(() => {
        if (String(window.navigator.userAgent).includes('Cider')) {
            setIa(JSON.parse(localStorage.getItem('ia') || null))
            setUa(new URLSearchParams(window.navigator.userAgent))
            setAppName(String(window.navigator.userAgent).split(';')[0].replace('-', ' '))
        }
    }, []);

    const remoteRedirect = useCallback(async () => {
        window.open("/classic/remote", '_blank', 'popup')
    }, []);

    if (authStatus !== "loading") return (
        <>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', justifyItems: 'center', backgroundColor: "rgb(39 42 50)", borderRadius: 10, padding: 20, width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                <Tooltip content={"Sign Out"} style={{ width: '80%' }}>
                    <Logout />
                </Tooltip>
                <Tooltip content={"Remote"} style={{ width: '80%' }}>
                    <Button
                        auto
                        style={{ width: '100%' }}
                        bordered
                        color="error"
                        icon={<svg width="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 12a1 1 0 0 1 1 1v9h-2v-8H8v8H6v-9a1 1 0 0 1 1-1h10zm-5 4v2h-2v-2h2zm0-10a6 6 0 0 1 5.368 3.316l-1.79.895a4 4 0 0 0-7.157 0l-1.789-.895A6 6 0 0 1 12 6zm0-4a10 10 0 0 1 8.946 5.527l-1.789.895A8 8 0 0 0 12 4a8 8 0 0 0-7.157 4.422l-1.79-.895A10 10 0 0 1 12 2z" />
                        </svg>}
                        onPress={remoteRedirect}
                    />
                </Tooltip>

                <Tooltip content={"Currently Playing Widget"} style={{ width: '80%' }}>
                    <WidgetButton />
                </Tooltip>

                <Tooltip content={"Info"} style={{ width: '80%' }}>
                    <Button
                        auto
                        style={{ width: '100%' }}
                        bordered
                        color="error"
                        icon={<svg width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" stroke="currentColor" strokeWidth="2" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm0-12v8m0-12v2" />
                        </svg>}
                        onPress={() => setVisible(true)}
                    />
                </Tooltip>
                <Modal
                    scroll
                    width="600px"
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    {...bindings}
                >
                    <Modal.Header>
                        <Text id="modal-title" size={18}>
                            About This {appName ? `${appName} ` : null}Client
                        </Text>
                    </Modal.Header>
                    <Modal.Body>
                        <Text id="modal-description">
                            <Text>
                                {ia ? <span>Application UUID: <code>{ia.uuid}</code></span> : null}
                            </Text>
                            <Text>
                                {'client' in ua ? <span>Client: <code>{ua.get('client')}</code></span> : 'Browser'}
                            </Text>
                            <Text>
                                {'env' in ua ? <span>Backend: <code>{ua.get('env')}</code></span> : 'Web'}
                            </Text>
                            <Text>
                                {'platform' in ua ? <span>Platform: <code>{ua.get('env')}</code></span> : null}
                            </Text>
                            <Text>
                                {ia ? <span>Connect Version: <code>v{ia.connectVersion}</code></span> : null}
                            </Text>
                            <Text>
                                {ia ? <span>Stripe Version: <code>v{ia.stripeVersion}</code></span> : null}
                            </Text>
                        </Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button auto flat color="error" onPress={() => setVisible(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}
