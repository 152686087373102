import { useAuth } from "reactfire";
import {
    signInWithCustomToken
} from "firebase/auth";
import { useRequestState } from "./useRequestState";
import { useCallback } from "react";

export default function useSignInWithToken() {
    const auth = useAuth();
    const { state, setLoading, setData, setError } = useRequestState();
    const signInWithProvider = useCallback(async (token) => {
        setLoading(true);
        try {
            const result = await signInWithCustomToken(auth, token)
            setData(result);
        } catch (error) {
            setError(error);
        }
    }, [auth, setData, setError, setLoading]);
    return [signInWithProvider, state];
}
