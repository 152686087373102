import { useFirestore, useUser } from "reactfire";
import {doc, setDoc} from "firebase/firestore";
import { Card, Loading } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useCallback } from "react";

export default function OpenCollectiveStatus() {
    const { status, data: user } = useUser();
    const firestore = useFirestore();
    const [total, setTotal] = useState(0);
    const [hasDonated, setHasDonated] = useState(false);
    const [isDev, setIsDev] = useState(false);

    const donateRedirect = useCallback(async () => {
        // Add the email so its picked up by firebase :)
        window.open(`https://opencollective.com/ciderapp/contribute/one-time-donation-35383/checkout/profile?interval=oneTime&email=${String(user.email).replace('@', '%40')}`, '_blank')
    }, [user.email]);

    useEffect(() => {
        async function getDonations() {
            if (!user) return;

            try {
                const check = await fetch(`https://get-oc-donations.ciderapp.workers.dev/?e=${encodeURI(user.email)}`);
                const data = await check.json();

                if (data?.hasDonated) {
                    setHasDonated(true);
                    setDoc(doc(firestore, `users/${user.uid}/accounts/opencollective`), {
                        ...data,
                    }, { merge: true });
                }

                if (data?.total) {
                    setTotal(data.total);
                }

                if (data?.isDev || String(user.email).includes("@cider.sh")) {
                    setIsDev(true);
                }
            } catch (error) {
                console.error(error);
            }
        }
        getDonations();
    }, [user, firestore]);

    if (status === "loading") return <Loading />;

    return (
        <>
            {isDev || hasDonated ?
                <Card isHoverable
                    style={{
                        borderRadius: 10,
                        backgroundColor: '#7aa2e3',
                        width: '100%',
                        height: 100,
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        alignContent: "flex-start",
                        padding: 20
                    }}
                >
                    <svg fill="white" style={{ width: 35 }} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.145 6.896c3.819 5.468 3.819 12.74 0 18.208l-4.129-4.131c1.713-3.093 1.713-6.853 0-9.947zM25.104 2.855l-4.131 4.129c-3.187-1.749-7.056-1.692-10.187 0.161-3.131 1.849-5.047 5.215-5.047 8.849 0 3.636 1.916 7 5.047 8.849s7 1.905 10.187 0.156l4.131 4.145c-4.891 3.391-11.26 3.781-16.531 1.021s-8.579-8.224-8.579-14.172c0-5.953 3.308-11.411 8.579-14.177 5.271-2.76 11.64-2.364 16.531 1.021zM29.145 6.896c3.819 5.468 3.819 12.74 0 18.208l-4.129-4.131c1.713-3.093 1.713-6.853 0-9.947z" />
                    </svg>
                    <div style={{ display: "inline-block", paddingLeft: 20 }}>
                        <h3 style={{ margin: "unset", textAlign: "left" }}>Open Collective</h3>
                        <h5 style={{ float: "left", textAlign: "start" }}>{isDev ? "You are a member of Cider Collective" : `You have donated ${total} in total`}</h5>
                    </div>
                </Card>
                : <Card isHoverable
                    isPressable
                    style={{
                        borderRadius: 10,
                        backgroundColor: '#7aa2e3',
                        width: '100%',
                        height: 100,
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        alignContent: "flex-start",
                        padding: 20
                    }}
                    onPress={donateRedirect}
                >
                    <svg fill="white" style={{ width: 35 }} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.145 6.896c3.819 5.468 3.819 12.74 0 18.208l-4.129-4.131c1.713-3.093 1.713-6.853 0-9.947zM25.104 2.855l-4.131 4.129c-3.187-1.749-7.056-1.692-10.187 0.161-3.131 1.849-5.047 5.215-5.047 8.849 0 3.636 1.916 7 5.047 8.849s7 1.905 10.187 0.156l4.131 4.145c-4.891 3.391-11.26 3.781-16.531 1.021s-8.579-8.224-8.579-14.172c0-5.953 3.308-11.411 8.579-14.177 5.271-2.76 11.64-2.364 16.531 1.021zM29.145 6.896c3.819 5.468 3.819 12.74 0 18.208l-4.129-4.131c1.713-3.093 1.713-6.853 0-9.947z" />
                    </svg>
                    <div style={{ display: "inline-block", paddingLeft: 20 }}>
                        <h3 style={{ margin: "unset", textAlign: "left" }}>Open Collective</h3>
                    </div>
                </Card>
            }
        </>
    );
}
