import { useDatabase, useDatabaseObjectData } from "reactfire";
import { ref } from "firebase/database";
import { useEffect, useState } from "react";
// https://discord.com/api/oauth2/authorize?client_id=960067993226854431&redirect_uri=http://localhost:3000/api/callback/discord&response_type=code&scope=identify
import {Card, Col, Loading, Text, Grid} from "@nextui-org/react";
import ContinueIA from "../ContinueIA";

export default function RelayPlaylist(props) {
  const database = useDatabase();
  /*
  const dbRef = ref(database, `playlists/${props.id}`);

  let { dbstatus, data: playlist } = useDatabaseObjectData(dbRef);

  if ( dbstatus === "loading" ) {
    return <Loading color="white" type="gradient" size="sm" />;
  }

  if (!playlist) {
    return (
      <div>
        <h2>Collaborative Playlists</h2>
        <div className="description">
          <b>This playlist</b> does not exist.
        </div>
      </div>
    );
  }

  //console.log(playlist);
   */
  return (
    <div>
      <h2>Collaborative Playlists</h2>
      <div className="description" style={{ justifyItems: "normal" }}>
        <Grid.Container gap={2} justify="center">
          <Grid>
          </Grid>
        </Grid.Container>
        <br />
        <br />
          <div id="ln" style={{ display: "flex", justifyContent: "center" }}>
            <ContinueIA rtd={{ command: "route", page: `connect/playlists/${props.id}` }}> Join Playlist </ContinueIA>
          </div>
        <br />
        <br />
      </div>
    </div>
  );
}
