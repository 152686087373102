import "./App.css";
import { Button, createTheme, NextUIProvider, Text } from '@nextui-org/react';
import { Redirect, Router, LocationProvider } from "@reach/router";
import { Helmet } from "react-helmet";
import Dashboard from "./components/dashboard";
import Continue from "./components/continue";
import LinkAccount from "./components/LinkAccount";
import LinkAccountFinish from "./components/LinkAccountFinish";
import RelayLtgh from "./components/relay/ltgh";
import ContinueIA from "./components/ContinueIA";
import Widget from "./components/relay/widget";
import Pricing from "./components/Pricing";
import ClassicRemoteQR from "./components/classicRemoteQR";
import { useEffect, useState } from "react";
import { hasCookie } from 'cookies-next';
import RelayPlaylist from "./components/relay/playlist";
const darkTheme = createTheme({
  type: "dark",
});

function App() {
  console.log(`%c[Connect Web Dashboard]%c`, "color:#ff4f44;font-weight:bold;", "", "hello!");

  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    if (String(window.location.pathname).includes('relay/widget') ||
      new URL(window.location.href).searchParams.has("relay") ||
      new URL(window.location.href).searchParams.has("ia") ||
      hasCookie("relay") ||
      hasCookie("ia") ||
      localStorage.getItem('ia') === "true" ||
      localStorage.getItem('relay') === "true") {
      setShowFooter(false)
    }
    if (new URL(window.location.href).searchParams.has("relay") ||
      new URL(window.location.href).searchParams.has("ia") ||
      hasCookie("relay") ||
      hasCookie('ia') ||
      localStorage.getItem('ia') === "true" ||
      // String(window.navigator.userAgent).contains("Cider-2") ||
      localStorage.getItem('relay') === "true") {
      document.body.style.backgroundColor = "transparent";
      document.documentElement.style.backgroundColor = "transparent";
    }
  }, []);
  // String(window.navigator.userAgent).contains("Cider-2")
  return (
    <div className="App">
      <Helmet> {/* If placed in other components, this gets overridden*/}
        <meta charSet="utf-8" />
        <title>Cider Connect</title>
        <link rel="canonical" href="https://connect.cidercollective.dev" />
        <meta name="theme-color" content="#ff2b51"></meta>
        <link rel="me" href="https://github.com/ciderapp"></link>
        <meta property="og:url" content="https://connect.cidercollective.dev" />
        <meta property="og:site_name" content="cider.sh" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cider Connect" />
        <script>
          {/*
      ⣿⣿⣿⣿⡿⠟⠛⠋⠉⠉⠉⠉⠉⠛⠛⠻⠿⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
      ⣿⣿⠟⠈⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠀⠈⠙⠾⣿⣾⣿⣾⣿⣾⣿⣾⣿
      ⠋⡁⠀⠀⠀⠀⠀⢀⠔⠁⠀⠀⢀⠠⠐⠈⠁⠀⠀⠁⠀⠈⠻⢾⣿⣾⣿⣾⣟⣿
      ⠊⠀⠀⠀⠀⢀⠔⠃⠀⠀⠠⠈⠁⠀⠀⠀⠀⠀⠀⠆⠀⠀⠄⠀⠙⣾⣷⣿⢿⣿
      ⠀⠀⠀⠀⡠⠉⠀⠀⠀⠀⠠⢰⢀⠀⠀⠀⠀⠀⠀⢰⠀⠀⠈⡀⠀⠈⢿⣟⣿⣿
      ⠀⠀⢀⡜⣐⠃⠀⠀⠀⣠⠁⡄⠰⠀⠀⠀⠀⠀⠀⠐⠀⠀⠀⠰⠀⠀⠈⢿⣿⣿
      ⠀⢠⠆⢠⡃⠀⠀⠀⣔⠆⡘⡇⢘⠀⠀⠀⠀⠀⠀⠈⠀⠀⠀⠀⠀⠀⠀⠀⣿⣿
      ⢀⡆⠀⡼⢣⠀⢀⠌⢸⢠⠇⡇⢘⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⣿
      ⣼⣃⠀⠁⢸⢀⠎⠀⢸⠎⠀⢸⢸⡄⠀⠀⠀⠀⠀⠂⢀⠀⠀⠀⠀⠀⠀⠀⠀⣿
      ⠃⡏⠟⣷⣤⠁⠀⠀⠸⠀⠀⡾⢀⢇⠀⠀⠀⠀⠀⠄⠸⠀⠀⠀⠀⠄⠀⠀⠀⣿
      ⠀⠀⣀⣿⣿⣿⢦⠀⠀⠀⠀⡧⠋⠘⡄⠀⠀⠀⠀⡇⢸⠀⠀⠠⡘⠀⠀⠀⢠⣿
      ⠈⠀⢿⢗⡻⠃⠀⠀⠀⠀⠀⠀⠀⠀⠱⡀⠀⠀⢰⠁⡇⠀⠀⢨⠃⡄⢀⠀⣸⣿
      ⠀⠀⠀⠈⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣱⠀⠀⡎⠸⠁⠀⢀⠞⡸⠀⡜⢠⣿⣿
      ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⣺⣿⣧⢰⣧⡁⡄⠀⡞⠰⠁⡸⣠⣿⣿⣿
      ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠠⡿⠏⣿⠟⢁⠾⢛⣧⢼⠁⠀⠀⢰⣿⡿⣷⣿
      ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠉⠡⠄⠀⡠⣚⡷⠊⠀⠀⠀⣿⡿⣿⡿⣿
      ⡀⠀⠀⠀⠀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡠⠊⠁⢸⠁⠀⠀⠀⢰⣿⣿⡿⣿⣿
      ⠱⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⡠⠊⠀⠀⠀⡞⠀⠀⠀⠀⢸⣿⣷⣿⣿⣿
      ⠀⠙⢦⣀⠀⠀⠀⠀⠀⢀⣀⣠⠖⠁⠀⠀⠀⠀⠀⡇⠀⠀⠀⠀⣸⣿⣾⡿⣷⣿
      ⠀⠀⠀⠀⠉⠉⣩⡞⠉⠁⠀⢸⡄⠀⠀⠀⠀⠀⢰⠇⠀⠀⠀⠀⣿⣿⣷⣿⣿⣿
      ⡆⠀⠀⣀⡠⠞⠁⣧⢤⣀⣀⣀⡇⠀⠀⠀⠀⠀⣸⠀⠀⠀⠀⠀⣿⣷⣿⣷⣿⣿
      ⣿⣷⠊⠁⠀⠀⡰⠁⠀⠀⠀⠀⣹⠶⢦⡀⠀⠀⡇⠀⠀⠀⠀⠀⢸⣿⣷⣿⣷⣿
      ⣿⢿⠀⠀⠀⡔⠁⠀⠀⠀⠀⠀⠀⠀⠀⠈⠳⡄⡇⠀⠀⠀⠀⠀⠈⣿⣾⣷⣿⣿
      ⠋⠈⠀⢀⠜⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡀⠀⠈⣧⠀⠀⠀⠀⠀⠀⠻⣿⣽⣾⣿
      ⢀⡄⡠⠊⠀⠀⠀⠀⠀⠀⠀⠀⠀⡀⠁⠀⠀⠀⣸⠀⠀⠀⠀⠀⠀⠀⣿⣿⣻⣿
      ⣿⠋⠀⠀⠀⠀⠀⠀⠀⠀⢰⠀⠐⠀⠀⠀⠀⣀⡿⠀⠀⠀⠀⠀⠀⠀⢹⣿⣻⣿
      ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡼⠀⠀⠀⠀⠀⢀⣃⡇⠀⠲⡀⠀⠀⠀⠀⠈⣿⡿⣿
      ⣀⠤⠤⠤⡀⠀⠀⠀⠀⡴⠃⠀⠀⠀⠀⠀⢬⠞⡇⠀⠀⠇⠀⠀⠀⠀⠀⣿⣿⣿
      ⡁⢀⠀⠀⡇⠀⠀⠀⡼⠁⠀⠀⠀⠀⠀⣸⠁⠀⠇⠀⠀⡇⠀⠀⠀⠀⠀⣿⣿⣿
      ⠔⠃⠀⠀⡇⠀⠀⡼⠁⠀⠀⠀⠀⠀⢀⡇⠀⠀⡃⠀⠀⠙⢄⠀⠀⠀⠀⣿⣷⣿
      ⠒⠊⠀⠀⢸⠀⣸⠃⠀⠀⠀⠀⠀⠀⡞⠀⠀⠀⢅⠀⠀⡂⠸⡄⠀⠀⠀⣿⣟⣿
      ⠓⠀⠉⠀⢸⣰⠃⠀⠀⠀⠀⠀⠀⡜⡆⠀⠀⠀⢸⠀⠀⡇⢀⠇⠀⠀⠀⣿⣿⣿
      ⠉⠁⠀⢠⠞⠀⠀⠀⠀⠀⠀⠀⣰⠁⡇⠀⠀⠀⡇⠀⠀⡇⢸⠀⠀⠀⠀⣿⣷⣿
      ⡀⠀⢀⢿⣥⡤⠤⠤⠤⣀⣀⢠⠇⠀⢸⠀⠀⢰⠁⠀⢨⠀⢸⠀⠀⠀⠀⣿⣟⣿
      */}
        </script>
      </Helmet>
      <NextUIProvider theme={darkTheme}>
        <LocationProvider>
          <Router>
            <Redirect from="/" to="/continue" />
            <Dashboard path="/dashboard" />
            <Continue path="/continue" />
            <LinkAccount path="/callback/success" />
            <LinkAccountFinish path="/linked/:account" />
            <RelayLtgh path="/relay/ltgh/:id" />
            <RelayPlaylist path="/relay/playlist/:id" />
            <Widget path="/relay/widget/:id" />
            <ContinueIA path="/cia" />
            <Pricing path="/pricing" />
            <ClassicRemoteQR path="/classic/remote" />
          </Router>


          {!showFooter ? null : (
            <footer>

              <h5 className="description" class={"content"}>
                <Button disabled >
                  <Text> Connect is currently having database connection issues. Some functionality may be limited.</Text>
                </Button>
                <br />
                &copy; {new Date().getFullYear()} Cider Collective - All rights
                reserved
              </h5>
              <h6>
                <a href="https://cider.sh/legal/policy" target={'_blank'} rel="noreferrer noopener" style={{ color: "#ff375f" }}>
                  <b>Privacy Policy</b>
                </a>
              </h6>
            </footer>
          )}
        </LocationProvider>
      </NextUIProvider>
    </div>
  );
}

export default App;
