import { Loading } from "@nextui-org/react";
import { useFirestore, useUser } from "reactfire";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "@reach/router";
import { Guarded } from "./guarded";
import {doc, setDoc} from "firebase/firestore";

export default function LinkAccount(props) {
    const [fbStatus, setFbStatus] = useState(false);
    const firestore = useFirestore();
    const location = useLocation();
    const navigate = useNavigate();
    const { status, data: user } = useUser();

    const locationRef = useRef(location.href);

    useEffect(() => {
        if (status === "loading") return;
        async function setData() {
            const { searchParams } = new URL(locationRef.current);
            if (!user) return;
            if (!searchParams.has("fbcallback")) {
                setFbStatus(true);
                return;
            }
            let passback = JSON.parse(decodeURI(atob(searchParams.get("fbcallback"))));
            console.log(Object.keys(passback)[0], Object.entries(passback)[0][1]);
            const ref = doc(firestore, `users/${user.uid}/accounts/${Object.keys(passback)[0]}`);
            await setDoc(ref, { ...Object.entries(passback)[0][1] }, { merge: true }).then(() => {
                    console.log("Document successfully updated!");
                }).catch((error) => {
                    console.error("Error writing document: ", error);
                });
            navigate(`/linked/${Object.keys(passback)[0]}`, { replace: true });
        }
        setData()
    }, [firestore, user, navigate, locationRef, status]);
    if (!fbStatus) return <Loading type="gradient" color="white">
        {" "}
        Linking...{" "}
    </Loading>;
    return <Guarded />
}
