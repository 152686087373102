import { Container } from "@nextui-org/react";
import { navigate } from "@reach/router";
import { useEffect } from "react";

export default function LinkAccountFinish(props) {
    // linked/:account
    useEffect(() => {
        return () => {
            window.top.postMessage({
                type: "close-account-link-model",
            }, '*')
        };
    }, []);

    const cb = props.location.search.includes("?cb=");
    if (cb) {
        const cbUrl = props.location.search.split("?cb=")[1];
        setTimeout(() => {
            if (cbUrl === "dashboard") {
                navigate(`/dashboard`);
            } else {
                window.open(cbUrl, '_blank', 'popup')
            }
        }, 5000);
    }

    return (
        <Container justify="center">
            <h1> Your {String(props.account).charAt(0).toUpperCase() + String(props.account).slice(1)} account is connected! </h1>
            <h5> You {cb ? "will be redirected in 5 seconds." : "can now close this tab."} </h5>
        </Container>
    );
}
