import { Button, Link, Spacer, Text } from "@nextui-org/react";
import { useLocation } from "@reach/router"
import { useEffect, useState } from "react";
import ClassicUpsell from "./ClassicUpsell";
export default function ClassicRemoteQR(props) {
    // Create a qr code that gets centered on the screen from the url param
    // Use NextUI Components to make it look nice
    let [copied, setCopied] = useState(false);

    const location = useLocation();
    const { searchParams } = new URL(location.href);
    searchParams.get('url');

    useEffect(() => {
        if (!String(decodeURI(searchParams.get('url')).includes(':'))) {
            //
            document.getElementById('qr-code').src = "https://raw.githubusercontent.com/feathericons/feather/f81cd40fdcdd5e94f3f97eb670a5058e3aac528d/icons/x.svg"
        } else {
            document.getElementById('qr-code').src = "https://api.qrserver.com/v1/create-qr-code/?size=140x140&data=" + decodeURI(searchParams.get('url')) + '&bgcolor=255-33-86&color=0-0-0&margin=10';
        }
    })

    function copyToClipboard() {
        navigator.clipboard.writeText(decodeURI(searchParams.get('url')));
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }


    return (
        <div className="flex items-center justify-center h-screen">
            <div className="flex flex-col space-y-8">
                <div className="content" style={{ justifyItems: "normal" }}>
                    <Text h1>Cider Remote</Text>
                    <Text h5>Avoid disruptions and enjoy the tranquility of switching music straight from your phone. </Text>
                    <Spacer y={1} />
                    <Link href={`${decodeURI(searchParams.get('url'))}`} target={"_blank"}>
                        <img id="qr-code" alt="qr-code" style={{ borderRadius: "30px", width: "200px", height: "200px" }} />
                    </Link>
                    <Spacer y={0.5} />
                    <Button light onPress={copyToClipboard} color={copied ? 'success' : 'primary'}>{copied ? 'Copied!' : 'Copy to clipboard'}</Button>
                    <Spacer y={0.5} />
                    <Text>Scan the QR code to pair your phone up with this Cider instance</Text>
                    <Spacer y={0.5} />
                    <ClassicUpsell />
                </div>

            </div>
        </div>
    );
}
