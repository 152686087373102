import { useUser, useDatabase, useDatabaseObjectData, useSigninCheck } from "reactfire";
import { Card, Tooltip, Button, Input } from "@nextui-org/react";
import { useCallback } from "react";
import { ref, set } from "firebase/database";

export default function ListenTogetherStatus() {
    const { status: authStatus, data: user } = useUser();
    const database = useDatabase();
    let { data: usr } = useDatabaseObjectData(ref(database, `users/${user.uid}`));
    const { status, data: signInCheckResult } = useSigninCheck();

    const hostParty = useCallback(async () => {
        set(
            ref(database, `users/${signInCheckResult.user.uid}/relay`),
            {
                command: "ltgh-serve"
            }
        )
    }, [database, signInCheckResult.user.uid]);


    if (authStatus !== "loading" && status !== 'loading') return (
        <>
            <Card
                isHoverable
                style={{
                    borderRadius: 10,
                    backgroundColor: "rgb(39 42 50)",
                    height: '100%',
                    display: "inline-flex",
                    flexWrap: "wrap",
                    padding: 20,
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <h3>{usr?.ltgh?.link ? 'You are currently serving' : 'Listen Together'}</h3>
                {usr?.ltgh?.link ? <>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4 style={{ color: 'rgb(255, 55, 95)', margin: 'unset', marginRight: 10 }}>{String(usr?.ltgh?.link).replace("https://", "")}</h4>
                        <Tooltip content={"Copy"} style={{ width: '80%' }}>
                            <Button
                                auto
                                bordered
                                color="error"
                                icon={
                                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy">
                                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
                                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                                    </svg>
                                }
                                onPress={navigator.clipboard.writeText(String(usr?.ltgh?.link).replace("https://", ""))}
                            />
                        </Tooltip>
                    </div>
                    <h5 style={{ color: 'gray' }}>Share this link with your friends to listen along</h5>
                </> : null}
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <Button
                        auto
                        bordered
                        color="error"
                        style={{ margin: '0.5rem' }}
                        onPress={hostParty}
                    >
                        {usr?.ltgh?.link ? 'Stop Serving' : 'Host Listening Party'}
                    </Button>
                    <Input
                        placeholder='or Enter Party Code'
                        color='error'
                        clearable
                        size="lg"
                        hidden={usr?.ltgh?.link ? true : false}
                    />
                </div>
            </Card>
        </>
    );
}