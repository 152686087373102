const Card = ({ name, description, price, yearly, subscription, features, buttonTitle, buttonUrl }) => (
    <div style={{ width: 400, borderRadius: 10, background: '#0f0f0f', display: 'flex', alignItems: 'flex-start', margin: '20px' }}>
        <div style={{ padding: 20 }}>
            <h3>{name}</h3>
            <p className='description'>{description}</p>

            <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p style={{ fontSize: 40, fontWeight: 700, marginRight: '0.75rem' }}>{price}$</p>
                {subscription ? (
                    <p className="description">per month</p>
                ) : (null)}
            </p>
            {yearly ? (
                <p className="description">or {yearly}$ per year</p>
            ) : (null)}

            <div style={{ padding: 20, display: "flex" }}>
                <ul style={{ listStyle: 'inside', padding: 0, margin: 0, textAlign: 'left' }}>
                    {features.map(feature => (
                        <li>{feature}</li>
                    ))}
                </ul>
            </div>

            <div style={{ padding: 20 }}>
                <a href={buttonUrl} target="_blank" rel="noreferrer">
                    <button className="subscriptionBtn">{buttonTitle}</button>
                </a>
            </div>
        </div>
    </div >
);


function Pricing() {
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="flex flex-col space-y-8">
                <h1 className="Hero">Pricing</h1>
                <p style={{ color: 'rgb(115 115 115)', fontSize: '1.25rem', lineHeight: '1.75rem', paddingLeft: '20rem', paddingRight: '20rem' }}>
                    We are deeply grateful to all of our supporters, as every contribution helps us sustain our services. Below are some of the ways you can support us and earn some perks in the process.
                </p>
                <br />
                <div className="content" style={{ justifyItems: "center", display: 'flex', 'flexDirection': 'row', 'alignItems': 'stretch', 'justifyContent': 'center', 'margin': '15px' }}>
                    <Card
                        name="Donate"
                        description="Any donation helps us a ton! Allows us to afford our domains and replenish our development resources. You gain Discord Benefits and credit in the app!"
                        price={2.50}
                        yearly={false}
                        subscription={false}
                        features={['Beta Access', 'Discord Perks', 'Higher Priority']}
                        buttonTitle="Donate"
                        buttonUrl="https://opencollective.com/ciderapp"
                    />
                    <Card
                        name="Connect Standard Plan"
                        description="Our standard subscription. The only one available cause i can't think of anything else"
                        price={1.99}
                        subscription={true}
                        features={['Alpha and Beta Access', 'More slots for Listen Together', 'Auto Spotify Playlist Syncing', 'Radio Station Hosting', 'Higher Priority']}
                        buttonTitle="Subscribe"
                        buttonUrl="https://pornhub.com"
                    />
                </div>
            </div>
        </div>
    );
}

export default Pricing;