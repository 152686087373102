import { useFirestore, useFirestoreDocData, useUser } from "reactfire";
import { doc } from "firebase/firestore";
import { Card, Loading } from "@nextui-org/react";

export default function UserStatus() {
    const { status: authStatus, data: user } = useUser();
    const firestore = useFirestore();
    const discordRef = doc(firestore, "users", user?.uid, "accounts", "discord");
    const { status: discordStatus, data: discordData } = useFirestoreDocData(discordRef);

    if (authStatus === "loading" || discordStatus === "loading") {
        return <Loading />;
    }

    return (
        <>
            <Card
                isHoverable
                style={{
                    borderRadius: 10,
                    backgroundColor: "rgb(39 42 50)",
                    height: '100%',
                    display: "inline-flex",
                    padding: 20,
                    flexFlow: 'row wrap',
                    flexDirection: 'row',
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <img
                    style={{ width: 65, borderRadius: "100%" }}
                    src={
                        discordData?.avatar && discordData?.id
                            ? `https://cdn.discordapp.com/avatars/${discordData.id}/${discordData?.avatar}.png`
                            : "https://raw.githubusercontent.com/ciderapp/cider.sh/main/assets/img/Cider-Logo.png"
                    }
                    alt="icon"
                />
                <div style={{ display: "inline-block", paddingLeft: 20 }}>
                    <h3 style={{ margin: "unset", textAlign: "left" }}>{user?.displayName || user?.displayName}</h3>
                    <h5 style={{ float: "left", filter: "brightness(75%)" }}>{user?.email}</h5>
                </div>
            </Card>
        </>
    );
}