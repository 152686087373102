import { useFirestore, useUser, useFirestoreDocData } from "reactfire";
import { doc, updateDoc } from "firebase/firestore";
import { Card, Loading, Switch } from "@nextui-org/react";

export default function DiscordStatus() {
  const { status, data: user } = useUser();
  const firestore = useFirestore();
  const ref = doc(firestore, "users", user.uid, "accounts", "discord");
  const { fsstatus, data } = useFirestoreDocData(ref);

  const discordRedirect = () => {
    window.open(`https://linkaccount.cider.sh/discord`, '_blank', 'popup')
  };

  let checked = data?.useDiscordAvatar || false;
  const useDiscordAvatar = () => {
    if (checked) checked = false;
    else checked = true;
    updateDoc(ref, { useDiscordAvatar: checked });
  }

  if (fsstatus || status === "loading") return <Loading />;

  return (
    <>
      {data?.username ?
        <Card
          isHoverable
          style={{
            borderRadius: 10,
            backgroundColor: "#5865F2",
            width: '100%',
            display: "inline-flex",
            flexWrap: "wrap",
            padding: 20,
            flexDirection: 'row',
          }}
        >
          <svg style={{ width: 35 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36">
            <path d="M107.7 8.07A105.15 105.15 0 0 0 81.47 0a72.06 72.06 0 0 0-3.36 6.83 97.68 97.68 0 0 0-29.11 0A72.37 72.37 0 0 0 45.64 0a105.89 105.89 0 0 0-26.25 8.09C2.79 32.65-1.71 56.6.54 80.21a105.73 105.73 0 0 0 32.17 16.15 77.7 77.7 0 0 0 6.89-11.11 68.42 68.42 0 0 1-10.85-5.18c.91-.66 1.8-1.34 2.66-2a75.57 75.57 0 0 0 64.32 0c.87.71 1.76 1.39 2.66 2a68.68 68.68 0 0 1-10.87 5.19 77 77 0 0 0 6.89 11.1 105.25 105.25 0 0 0 32.19-16.14c2.64-27.38-4.51-51.11-18.9-72.15ZM42.45 65.69C36.18 65.69 31 60 31 53s5-12.74 11.43-12.74S54 46 53.89 53s-5.05 12.69-11.44 12.69Zm42.24 0C78.41 65.69 73.25 60 73.25 53s5-12.74 11.44-12.74S96.23 46 96.12 53s-5.04 12.69-11.43 12.69Z" fill='#fff' data-name="Discord Logo - Large - White" />
          </svg>
          <div style={{ display: "inline-block", paddingLeft: 20 }}>
            <h3 style={{ margin: "unset", textAlign: "left" }}>{data.username}#{data.discriminator}</h3>
            <h5 style={{ float: "left" }}>Discord - Linked</h5>
          </div>
          <div className="discord-switch-box">
            <Switch className="switch" initialChecked={checked} shadow onChange={useDiscordAvatar}></Switch>
            <span style={{ paddingLeft: "20px" }}> Use Discord Avatar for Connect</span>
          </div>
        </Card>
        :
        <Card
          isPressable
          style={{
            borderRadius: 10,
            backgroundColor: "#5865F2",
            width: '100%',
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            padding: 20,
            flexDirection: 'row',
            justifyContent: 'flex-start'
          }}
          onPress={discordRedirect}
        >
          <svg style={{ width: 35 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36">
            <path d="M107.7 8.07A105.15 105.15 0 0 0 81.47 0a72.06 72.06 0 0 0-3.36 6.83 97.68 97.68 0 0 0-29.11 0A72.37 72.37 0 0 0 45.64 0a105.89 105.89 0 0 0-26.25 8.09C2.79 32.65-1.71 56.6.54 80.21a105.73 105.73 0 0 0 32.17 16.15 77.7 77.7 0 0 0 6.89-11.11 68.42 68.42 0 0 1-10.85-5.18c.91-.66 1.8-1.34 2.66-2a75.57 75.57 0 0 0 64.32 0c.87.71 1.76 1.39 2.66 2a68.68 68.68 0 0 1-10.87 5.19 77 77 0 0 0 6.89 11.1 105.25 105.25 0 0 0 32.19-16.14c2.64-27.38-4.51-51.11-18.9-72.15ZM42.45 65.69C36.18 65.69 31 60 31 53s5-12.74 11.43-12.74S54 46 53.89 53s-5.05 12.69-11.44 12.69Zm42.24 0C78.41 65.69 73.25 60 73.25 53s5-12.74 11.44-12.74S96.23 46 96.12 53s-5.04 12.69-11.43 12.69Z" fill='#fff' data-name="Discord Logo - Large - White" />
          </svg>
          <div style={{ display: "inline-block", paddingLeft: 20 }}>
            <h3 style={{ margin: "unset", textAlign: "left" }}>Link Discord</h3>
          </div>
        </Card>
      }
    </>
  );
}