import { useAuth } from "reactfire";
import {
    signInWithPopup,
    browserPopupRedirectResolver,
} from "firebase/auth";

import { useRequestState } from "./useRequestState";
import { useCallback } from "react";

export function useSignInWithGoogle() {
    const auth = useAuth();
    const { state, setLoading, setData, setError } = useRequestState();
    const signInWithGoogle = useCallback(async (provider) => {
        setLoading(true);
        try {
            const credential = await signInWithPopup(
                auth,
                provider,
                browserPopupRedirectResolver
            );
            setData(credential);
        } catch (error) {
            setError(error);
        }
    }, [auth, setData, setError, setLoading]);
    return [signInWithGoogle, state];
}
