import { useFirestore, useUser, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";
import { Card, Loading } from "@nextui-org/react";
import { useCallback } from "react";

export default function SpotifyStatus() {
    const { status, data: user } = useUser();
    const firestore = useFirestore();
    const ref = doc(firestore, "users", user.uid, "accounts", "spotify");
    const { fsstatus, data } = useFirestoreDocData(ref);

    const spotifyRedirect = useCallback(async () => {
        window.open(`https://linkaccount.cider.sh/spotify`, '_blank', 'popup')
    }, []);

    if (fsstatus || status === "loading") return <Loading />;

    return (
        <>
            {data?.access_token || data?.username ?
                <Card isHoverable
                    style={{
                        borderRadius: 10,
                        backgroundColor: "#1DB954",
                        width: '100%',
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "flex-start",
                        padding: 20,
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}
                >
                    <svg style={{ width: 35 }} width="32" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <path d="M10 1.2A8.798 8.798 0 0 0 1.2 10 8.8 8.8 0 1 0 10 1.2zm3.478 13.302c-.173 0-.294-.066-.421-.143-1.189-.721-2.662-1.099-4.258-1.099-.814 0-1.693.097-2.61.285l-.112.028c-.116.028-.235.059-.326.059a.651.651 0 0 1-.661-.656c0-.373.21-.637.562-.703a14.037 14.037 0 0 1 3.152-.372c1.855 0 3.513.43 4.931 1.279.243.142.396.306.396.668a.655.655 0 0 1-.653.654zm.913-2.561c-.207 0-.343-.079-.463-.149-2.143-1.271-5.333-1.693-7.961-.993a3.742 3.742 0 0 0-.12.037c-.099.031-.191.062-.321.062a.786.786 0 0 1-.783-.788c0-.419.219-.712.614-.824 1.013-.278 1.964-.462 3.333-.462 2.212 0 4.357.555 6.038 1.561.306.175.445.414.445.771a.784.784 0 0 1-.782.785zm1.036-2.92c-.195 0-.315-.047-.495-.144-1.453-.872-3.72-1.391-6.069-1.391-1.224 0-2.336.135-3.306.397a2.072 2.072 0 0 0-.098.027 1.281 1.281 0 0 1-.365.068.914.914 0 0 1-.919-.929c0-.453.254-.799.68-.925 1.171-.346 2.519-.521 4.006-.521 2.678 0 5.226.595 6.991 1.631.332.189.495.475.495.872a.908.908 0 0 1-.92.915z" />
                    </svg>
                    <div style={{ display: "inline-block", paddingLeft: 20 }}>
                        <h3 style={{ margin: "unset", textAlign: "left" }}>{data?.username ? data?.username : 'Spotify'}</h3>
                        <h5 style={{ float: "left" }}>Spotify - Linked</h5>
                    </div>
                </Card>
                : <Card isHoverable
                    isPressable
                    style={{
                        borderRadius: 10,
                        backgroundColor: "#1DB954",
                        width: '100%',
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "flex-start",
                        padding: 20,
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}
                    onPress={spotifyRedirect}
                >
                    <svg style={{ width: 35 }} width="32" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <path d="M10 1.2A8.798 8.798 0 0 0 1.2 10 8.8 8.8 0 1 0 10 1.2zm3.478 13.302c-.173 0-.294-.066-.421-.143-1.189-.721-2.662-1.099-4.258-1.099-.814 0-1.693.097-2.61.285l-.112.028c-.116.028-.235.059-.326.059a.651.651 0 0 1-.661-.656c0-.373.21-.637.562-.703a14.037 14.037 0 0 1 3.152-.372c1.855 0 3.513.43 4.931 1.279.243.142.396.306.396.668a.655.655 0 0 1-.653.654zm.913-2.561c-.207 0-.343-.079-.463-.149-2.143-1.271-5.333-1.693-7.961-.993a3.742 3.742 0 0 0-.12.037c-.099.031-.191.062-.321.062a.786.786 0 0 1-.783-.788c0-.419.219-.712.614-.824 1.013-.278 1.964-.462 3.333-.462 2.212 0 4.357.555 6.038 1.561.306.175.445.414.445.771a.784.784 0 0 1-.782.785zm1.036-2.92c-.195 0-.315-.047-.495-.144-1.453-.872-3.72-1.391-6.069-1.391-1.224 0-2.336.135-3.306.397a2.072 2.072 0 0 0-.098.027 1.281 1.281 0 0 1-.365.068.914.914 0 0 1-.919-.929c0-.453.254-.799.68-.925 1.171-.346 2.519-.521 4.006-.521 2.678 0 5.226.595 6.991 1.631.332.189.495.475.495.872a.908.908 0 0 1-.92.915z" />
                    </svg>
                    <div style={{ display: "inline-block", paddingLeft: 20 }}>
                        <h3 style={{ margin: "unset", textAlign: "left" }}>Link Spotify</h3>
                    </div>
                </Card>
            }
        </>
    );
}