import { useAuth, useDatabase, useSigninCheck } from 'reactfire';
import { Button, Loading } from '@nextui-org/react';
import { ref, set } from 'firebase/database';
import { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { signOut } from 'firebase/auth';
import { hasCookie } from 'cookies-next';

export default function Logout(props) {
	const { status, data: signInCheckResult } = useSigninCheck();
	const database = useDatabase();
	const auth = useAuth();
	const navigate = useNavigate();

	const [state, setState] = useState(false);

	useEffect(() => {
		const handleLogout = () => {
			if (
				hasCookie('relay') ||
				hasCookie('ia') ||
				localStorage.getItem('relay') === 'true' ||
				String(window.navigator.userAgent).includes('Cider')
			) {
				set(ref(database, `users/${signInCheckResult.user.uid}/relay`), {
					command: 'logout',
				});
			} else {
				signOut(auth).then(() => {
					navigate('/continue');
				});
			}
		};
		if (state) {
			handleLogout();
		}
	}, [auth, database, navigate, signInCheckResult.user.uid, state]);

	if (status === 'loading') {
		return null;
	}

	if (signInCheckResult.signedIn === true) {
		return (
			<Button
				auto
				bordered
				style={{ width: '100%' }}
				color="error"
				icon={
					<svg
						width="24"
						viewBox="0 0 24 24"
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M3 5c0-1.1.9-2 2-2h8v2H5v14h8v2H5c-1.1 0-2-.9-2-2V5Zm14.176 6L14.64 8.464l1.414-1.414 4.95 4.95-4.95 4.95-1.414-1.414L17.176 13H10.59v-2h6.586Z" />
					</svg>
				}
				onPress={() => {
					setState(true);
				}}
			>
				{state ? <Loading color="white" type="gradient" size="sm" /> : props.children}
			</Button>
		);
	} else {
		return null;
	}
}
