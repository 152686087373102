import {Button, Link, Text} from "@nextui-org/react";

export default function ClassicUpsell() {
    return (<Link href={'https://cider.sh/vs'} target={"_blank"}>
            <Button bordered color="warning" auto>
                <Text> ⚠️ Cider Classic is not maintained. Update to Cider 2 today!</Text>
            </Button>
        </Link>
    )
}
