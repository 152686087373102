import { Guarded } from './guarded';
import { Card, Container, Loading } from '@nextui-org/react';
import { useEffect } from 'react';
import { useParams } from '@reach/router';
import UserStatus from './cards/UserStatus';
import ListeningToStatus from './cards/ListeningToStatus';
import ListenTogetherStatus from './cards/ListenTogetherStatus';
import MenuControls from './cards/MenuControls';
import OpenCollectiveStatus from './cards/OpenCollectiveStatus';
import LastfmStatus from './cards/LastfmStatus';
import SpotifyStatus from './cards/SpotifyStatus';
import DiscordStatus from './cards/DiscordStatus';
import { useUser } from 'reactfire';

function Dashboard() {
  const params = useParams();
  useEffect(() => {
    if (params.ia || localStorage.getItem('ia')) {
      document.body.style.backgroundColor = 'transparent';
      document.documentElement.style.backgroundColor = 'transparent';
    }
  });

  const { status: authStatus, data: user } = useUser();

  if (authStatus === 'loading') {
    return <Loading />;
  }

  return (
    <Guarded>
      <Container style={{ display: 'flex' }}>
        <Card style={{ margin: '1rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <h2 className="Hero" style={{ margin: 'unset' }}>
              Connect Dashboard
            </h2>
            <div
              style={{
                border: '2px solid',
                borderRadius: 10,
                padding: '2px 5px',
                textAlign: 'center',
                textTransform: 'uppercase',
                backgroundColor: 'transparent',
                margin: 10,
                filter: 'brightness(75%)',
                fontSize: 'small',
              }}
            >
              {user?.stripeRole || user?.stripeRole === 'subscribed' ? 'Premium' : 'Free'}
            </div>
          </div>
          <div className="dashboardContainer">
            <div>
              <Card
                style={{
                  borderRadius: 10,
                  display: 'grid',
                  gap: 16,
                  justifyItems: 'center',
                  height: '100%',
                }}
              >
                <UserStatus />
                <ListeningToStatus />
                <ListenTogetherStatus />
                <MenuControls />
              </Card>
            </div>
            <div>
              <Card
                style={{
                  borderRadius: 10,
                  backgroundColor: 'rgb(39 42 50)',
                  display: 'grid',
                  gap: 16,
                  justifyItems: 'center',
                  padding: 20,
                }}
              >
                <OpenCollectiveStatus />
                <DiscordStatus />
                <LastfmStatus />
                <SpotifyStatus />
              </Card>
            </div>
          </div>
        </Card>
      </Container>
    </Guarded>
  );
}

export default Dashboard;
